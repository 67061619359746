import { Organization } from "~/src/api";

const fetcher = async (orgId: string) => {
  if (orgId === "") return null;

  return await Organization.getSubscriptionTiers(orgId, {
    order: { amount: "asc" },
    currency: "USD",
    showDeleted: false,
  });
};

type UseAsyncOrgTiersReturn = {
  data: Ref<null | Awaited<ReturnType<typeof fetcher>>>;
  error: Ref<null | Error>;
  pending: Ref<boolean>;
  refresh: () => Promise<Awaited<ReturnType<typeof fetcher>> | null>;
};

const asyncOrgTiersMap = new Map<string, UseAsyncOrgTiersReturn>();

export function useAsyncOrgTiers(orgId: MaybeRef<string>) {
  return computed<UseAsyncOrgTiersReturn>(() => {
    const orgIdValue = unref(orgId);

    const foundAsyncOrgTiersReturn = asyncOrgTiersMap.get(orgIdValue);
    if (foundAsyncOrgTiersReturn) return foundAsyncOrgTiersReturn;

    const pending = ref(true);
    const data = ref<null | Awaited<ReturnType<typeof fetcher>>>(null);
    const error = ref<null | Error>(null);
    const refresh = async () => {
      pending.value = true;

      return await fetcher(orgIdValue)
        .then((result) => {
          pending.value = false;
          error.value = null;
          data.value = result;
          return result;
        })
        .catch((error) => {
          pending.value = false;
          error.value = error;
          data.value = error;
          return null;
        });
    };

    refresh();

    const useAsyncOrgTiersReturn = {
      data,
      pending,
      error,
      refresh,
    } satisfies UseAsyncOrgTiersReturn;

    asyncOrgTiersMap.set(orgIdValue, useAsyncOrgTiersReturn);

    return useAsyncOrgTiersReturn;
  });
}
